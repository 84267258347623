import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import * as moment from 'moment';
import { Http, Headers } from '@angular/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { GeneralService } from './general.service';
import { Observable } from 'rxjs';
import { MainService } from './main.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  /** Request */
  public headers: Headers;
  public baseUrl = environment.baseUrl;
  /* public baseUrl: string = 'http://localhost:8081/'; */
  //public baseUrl: string = 'https://persing.herokuapp.com/';

  constructor(public http: Http, private generalService: GeneralService, private mainService: MainService) {
    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/json');
  }

  // =================================
  //  Metodos
  // ================================

  /**
   * Asingación sesión
   * @param {any} user
   */
  public setAuthValues({ data }: { data: any }): boolean {
    try {
      localStorage.removeItem('_user');
      localStorage.removeItem('_token');
      localStorage.removeItem('_expiresIn');

      const _token = `${data.token}`;
      this.mainService.headers = this.mainService.headers.set('authorization', _token);
      const _expiresIn = moment().add(data.expiresIn, 'second');

      delete data.token;
      delete data.expiresIn;
      const _user = Object.assign({}, data.user);

      localStorage.setItem('_user', JSON.stringify(_user));
      localStorage.setItem('_token', _token);
      localStorage.setItem('_expiresIn', JSON.stringify(_expiresIn.valueOf()));

      return true;
    } catch (error) {}
  }

  /**
   * User is admin PROGEN
   */
  public get isSuperadmin(): boolean {
    const _user: any = JSON.parse(localStorage.getItem('_user' || '{}')) || {};
    return _user.tipo && (_user.tipo === 'superadministrador' || _user.tipo === 'administrador');
  }

  /**
   * Retorna el usuario en sesión
   * @return {any} user
   */
  public getUser(): any {
    return JSON.parse(localStorage.getItem('_user'));
  }

  /**
   * Retorna el usuario en sesión
   * @return {any} user
   */
  public getToken(): string {
    return localStorage.getItem('_token');
  }

  /**
   * Verifca si el usario está en sesión
   */
  public isLoggedIn(): boolean {
    const _user: any = JSON.parse(localStorage.getItem('_user' || '{}')) || {};
    const _token: string = localStorage.getItem('_token') || '';
    if (this.isSuperadmin) return _token && _user._id;
    else {
      return false;
    }
  }

  /**
   * Reotrna el usuario en sesión
   * @return {any} user
   */
  public logout(): boolean {
    localStorage.removeItem('_user');
    localStorage.removeItem('_token');

    return true;
  }

  /**
   * API Generico POST
   * @param {string} api route: api/componente
   * @param {any} data objeto a persistir
   * @return {Observable<any>} respuesta asincrónica
   */
  public post({ api, data }: { api: String; data: any }): Promise<any> {
    const request = this.http
      .post(`${this.baseUrl}${api}`, data, {
        headers: this.headers,
      })
      .pipe(map((res) => res.json()));

    return this.responseHandler({ request });
  }

  /**
   * Obtiene una respuesta del servidor
   * @param {Observable} request
   */
  private responseHandler({ request }: { request: Observable<any> }): Promise<any> {
    return new Promise((resolve) => {
      request.subscribe(
        (response) => {
          if (response) {
            if (response.success) {
              if (response.user !== undefined && response.user != null) resolve(response);
              else {
                this.generalService.handleError({
                  error: 'Usuario no registrado.',
                });
                resolve(undefined);
              }
            } else {
              this.generalService.handleError({ error: response.error });
              resolve(undefined);
            }
          } else {
            this.generalService.handleResponseNull({});
            resolve(undefined);
          }
        },
        (error) => {
          const _body: any = JSON.parse(error._body || '{}');
          if (_body.error)
            this.generalService.handleError({
              error: `${_body.error}`,
            });
          else this.generalService.handleError({ error });
          resolve(undefined);
        }
      );
    });
  }
}
